import '../../styles/cards.css';

function GhostCard() {
    return (
      <div className="ghostCard"></div>
    )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    card: GhostCard
};